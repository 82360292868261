import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from './user.service';

@Injectable()
export class GuardService implements CanActivate {

  constructor(private userService: UserService, private router: Router) {}

  canActivate() {
    let isAuthenticated = this.userService.isAuthenticated();
    if (!isAuthenticated) this.router.navigate(['/login']);
    return isAuthenticated;
  }
}
