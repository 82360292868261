import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {Injectable, NgZone} from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router,
              private zone: NgZone) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        succ => {},
        err => {
          if (err.status === 401 || err.status === 403) {
            localStorage.removeItem('token');
            localStorage.removeItem('profile');
            this.zone.runOutsideAngular(() => {
              location.reload();
            });
          }
        }
      )
    );
  }
}
