import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    title: true,
    name: 'Management'
  },
  {
    name: 'Splits',
    url: '/splits',
    icon: 'cil-people'
  },
  {
    title: true,
    name: 'Settings'
  },
  {
    name: 'Users',
    url: '/users',
    icon: 'cil-group'
  }
];
