import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";
import Utils from "../../_helpers/utils";

@Component({
  templateUrl: '500.component.html'
})
export class P500Component implements OnInit {
  Utils = Utils;

  constructor(private title: Title) {}

  ngOnInit() {
    this.title.setTitle(Utils.appName() + " - Internal Server Error");
  }

}
