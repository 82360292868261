import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class DefaultPageService implements CanActivate {

  constructor(private router: Router) {}

  canActivate() {
    this.router.navigate(['/splits']);
    return true;
  }
}
