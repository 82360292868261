import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";
import {HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {UserService} from "./_services/user.service";
import {GuardService} from "./_services/guard.service";
import { DefaultPageService} from "./_services/default-page.service";
import {ToasterModule} from 'angular2-toaster';

import { P404Component } from './_routes/error/404.component';
import { P500Component } from './_routes/error/500.component';
import {AppAsideModule, AppBreadcrumbModule, AppFooterModule, AppHeaderModule, AppSidebarModule} from "@coreui/angular";
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";

import {
  NgbButtonsModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbPopoverModule,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';

import {JwtModule} from "@auth0/angular-jwt";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {ChangePasswordComponent} from "./_routes/home/users/change-password/change-password.component";

import {ModalModule} from "ngx-bootstrap/modal";
import {AuthInterceptor} from "./_services/http-interceptor.interceptor";
import {IsAdminService} from "./_services/isAdmin.service";

export function tokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
    P404Component,
    P500Component,
    ChangePasswordComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BsDatepickerModule.forRoot(),
    ToasterModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    }),
    NgbTooltipModule,
    NgbDropdownModule,
    NgbButtonsModule,
    NgbModalModule,
    NgbPopoverModule,
    ModalModule
  ],
  providers: [
    UserService,
    GuardService,
    IsAdminService,
    DefaultPageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  entryComponents: [
    ChangePasswordComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
