import {Component, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";
import Utils from "../../_helpers/utils";

@Component({
  templateUrl: '404.component.html'
})
export class P404Component implements OnInit {
  Utils = Utils;

  constructor(private title: Title) {}

  ngOnInit() {
    this.title.setTitle(Utils.appName() + " - Page Not Found");
  }
}
