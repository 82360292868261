import { Injectable, NgZone } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import {ToasterService as ToastrService} from "angular2-toaster";
import * as $ from 'jquery/dist/jquery.min.js';

@Injectable()
export class UserService {

  // Create a stream of logged in status to communicate throughout app
  loggedIn: boolean;
  // @ts-ignore
  loggedIn$ = new BehaviorSubject<boolean>(this.loggedIn);
  isLoginActive: boolean = false;
  isManager = false;
  isAdmin = false;
  isSuperAdmin = false;
  id = null;

  public processingLogin = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private toastr: ToastrService,
    private zone: NgZone) {

    if (this.isAuthenticated()) this.afterSignedIn();
    else $("body").addClass("not-logged-in");
  }

  public afterSignedIn() {
    this.setLoggedIn(true);
    this.refreshProfile(null);
  }

  public getUserProfile() {
    let profile = localStorage.getItem("profile");
    if (!profile) return false;
    let profileJSON = null;
    try {
      profileJSON = JSON.parse(profile);
    } catch(e) {}
    if (!profileJSON) return false;
    return profileJSON;
  }

  public isAuthenticated() {
    // Check if there's an unexpired access token
    const helper = new JwtHelperService();
    const myRawToken = localStorage.getItem('token');
    return !helper.isTokenExpired(myRawToken);
  }

  public signup(registerModel: any, loading: any) {
    loading = true;
    this.http.post('/api/auth/signup', registerModel).subscribe(
      data => {
        this._setSession(data["data"]);
        this.setLoggedIn(true);
        this.router.navigate(["/"], { queryParams: {welcome: 'true'}});
      },
      error => {
        let errorMessage = "An error occurred trying to sign you up, please check the information and try again";
        if (error.error && error.error.details && error.error.details.indexOf("A record with that `email` already exists") != -1)
          errorMessage = "This e-mail is already registered, please try to sign in or retrieve your password";
        this.toastr.pop("error", "", errorMessage);
        loading = false;
      });
  }

  public refreshProfile(callback) {
    this.http.get('/api/user/refresh').subscribe(
      (data:any) => {
        localStorage.setItem('profile', JSON.stringify(data));
        if (data.type === 'admin' ||
          data.type === 'super-admin' ||
          data.type === 'manager') {
          this.isManager = true;
        }

        if (data.type === 'admin' ||
          data.type === 'super-admin') {
          this.isAdmin = true;
        }

        if (data.type === 'super-admin') {
          this.isSuperAdmin = true;
        }

        this.id = data.id;
        if (callback) callback();
      });
  }

  public clearToasts() {
    this.toastr.clear();
  }

  public async signin(loginModel: any) {
    this.http.post('/api/auth/signin', loginModel).subscribe(
      async (data) => {
        this._setSession(data);
        this.toastr.clear();
        await this.router.navigate(["/"]);
        window.location.reload();
        this.afterSignedIn();
      },
      (res) => {
        let errorMessage = "Incorrect credentials, please try again."
        if (res.error === 'Forbidden') errorMessage = "You do not have access to this application. Please contact an administrator.";
        this.toastr.pop("error", "", errorMessage);
      });
  }

  async signinOrVerify(loginModel: any) {
    let data = null;

    this.processingLogin = true;

    try {
      data = await this.http.post('/api/auth/signin', loginModel).toPromise();
      if (data.success) {
        this._setSession(data);
        return data;
      }
      else {
        this.processingLogin = false;
        throw new Error(data);
      }
    }
    catch (e) {
      this.processingLogin = false;
      let errorMessage = "Incorrect credentials, please try again."

      if (e.statusText === 'Forbidden') errorMessage = "You do not have access to this application. Please contact an administrator.";

      this.toastr.pop("error", "", errorMessage);

      return false;
    }
  }

  public recover(recoverModel: any) {
    this.http.post('/api/auth/recover', recoverModel).subscribe(
      () => {
        this.toastr.pop("success", "", 'An e-mail has been sent to ' + recoverModel.email + ' with further instructions');
        this.router.navigate(["/"]);
      },
      () => {
        let errorMessage = "An error occurred trying to recover your password, please check the information and try again";
        this.toastr.pop("error", "", errorMessage);
      });
  }

  public resetpass(resetpassModel: any) {
    this.http.post('/api/auth/resetpass', resetpassModel).subscribe(
      () => {
        this.toastr.pop("success", "", 'Your password was updated');
        this.router.navigate(["/"]);
      },
      (e) => {
        let errorMessage = "An error occurred trying to update your password, please check the information and try again";
        console.log(e);
        if (e.error?.error === 'The token is expired.') errorMessage = 'The password reset link has expired. Please request a new one.';
        else if (e.error?.error === 'Could not find user.') errorMessage = 'The password reset link is invalid. Please request a new one.';
        this.toastr.pop("error", "", errorMessage);
      });
  }

  private _setSession(data) {
    localStorage.setItem('token', data.token);
    localStorage.setItem('profile', JSON.stringify(data.user));
  }

  public setLoggedIn(value: boolean) {
    // Update login status subject
    this.loggedIn$.next(value);
    this.loggedIn = value;
    if (this.loggedIn) $("body").removeClass("not-logged-in");
  }

  logout() {
    // Remove tokens and profile and update login status subject
    localStorage.removeItem('token');
    localStorage.removeItem('profile');
    this.setLoggedIn(false);
    $("body").empty();
    this.reloadPage(true);
  }

  reloadPage(home) {
    this.zone.runOutsideAngular(() => {
      if (home === true) location.href = '/';
      else location.reload();
    });
  }

  toggleClass() {
    this.isLoginActive = !this.isLoginActive;
    this.updateClass();
  }

  updateClass() {
    if (this.isLoginActive) {
      setTimeout(function() {
        $('.front .form-button-container').hide();
        $('.back .form-button-container').show();
      }, 300);
    }
    else {
      setTimeout(function() {
        $('.front .form-button-container').show();
        $('.back .form-button-container').hide();
      }, 300);
    }
  }

  profile(key, value?) {
    let profile = this.getUserProfile();
    if (value === null || value === undefined) return profile[key];
    else {
      profile[key] = value;
      localStorage.setItem('profile', JSON.stringify(profile));
    }
  }

  public setProcessingLogin(value) {
    this.processingLogin = value;
  }
}
