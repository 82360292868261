<toaster-container [toasterconfig]="toasterconfig"></toaster-container>
<div class="d-none d-lg-block force-show-on-print" *ngIf="inited">
  <ng-container *ngIf="userService.loggedIn && !userService.processingLogin">
    <div class="app-header navbar hide-on-print">
      <button class="navbar-toggler sidebar-toggler" type="button" data-toggle="sidebar-show" (click)="toggleMinimized($event)">
        <span class="navbar-toggler-icon"></span>
      </button>
      <a class="navbar-brand" routerLink="/">
        <img class="navbar-brand-full" src="images/logo.png" width="140" height="42" alt="{{Utils.appName()}} Logo" style="object-fit: contain;">
      </a>
      <ul class="nav navbar-nav">
        <li class="nav-item px-3 header-subtitle">{{page}}</li>
      </ul>
      <ul class="nav navbar-nav banner-container" *ngIf="bannerMessage">
        <li class="nav-item px-3 header-subtitle" title="{{bannerMessage}}">{{bannerMessage}}</li>
      </ul>
      <ul class="nav navbar-nav ml-auto mr-2">
        <li class="nav-item dropdown" dropdown placement="bottom right">
          <span class="nav-item-user-menu" dropdownToggle>
            <a class="nav-link" data-toggle="dropdown" href="javascript:;" role="button"
               aria-haspopup="true" aria-expanded="false">
              <i class="fa fa-user"></i>
            </a>
            <i class="fa fa-caret-down cursor-pointer"></i>
          </span>
          <div class="dropdown-menu dropdown-menu-right" *dropdownMenu
               aria-labelledby="simple-dropdown">
            <a class="dropdown-item" href="javascript:;" (click)="changePassword()"><i class="fa fa-key"></i> Change Password</a>
            <a class="dropdown-item" href="#" (click)="userService.logout()"><i class="fa fa-lock"></i> Logout</a>
          </div>
        </li>
      </ul>
    </div>
    <div class="app-body" *ngIf="canShowNavigation">
      <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized" (minimizedChange)="toggleMinimize($event)" class="hide-on-print">
        <app-sidebar-nav [navItems]="appNavItems" [perfectScrollbar] [disabled]="appSidebar.minimized"></app-sidebar-nav>
        <app-sidebar-minimizer></app-sidebar-minimizer>
      </app-sidebar>
      <main class="main">
        <div class="container-fluid bg-light-gray p-0">
          <router-outlet></router-outlet>
        </div>
      </main>
    </div>
  </ng-container>
  <ng-container *ngIf="!userService.loggedIn || userService.processingLogin">
    <router-outlet></router-outlet>
  </ng-container>
</div>
<div class="d-block d-lg-none low-resolution-message hide-on-print">
  <div class="card-body mx-auto p-0 h-100">
    <div class="login-logo" style="height: 20vh"></div>
    <div class="logo-shade" style="height: 80vh">
      <div class="text-container">
        <p>This application does not work on mobile devices.</p>
        <p>Please log in from a tablet or desktop computer.</p>
      </div>
    </div>
  </div>
</div>
