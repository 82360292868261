import {Component, OnInit, ViewChild} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {UserService} from "./_services/user.service";
import {Title} from "@angular/platform-browser";
import { navItems } from './nav';
import {ToasterConfig} from "angular2-toaster";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ChangePasswordComponent} from "./_routes/home/users/change-password/change-password.component";
import {HttpClient} from "@angular/common/http";
import Utils from "./_helpers/utils";

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('appSidebar', { static: false }) appSidebar;

  Utils = Utils;

  app = null;

  public sidebarMinimized = false;

  public navItems = navItems;

  appNavItems = [];

  public toasterconfig: ToasterConfig =
    new ToasterConfig({
      timeout: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    });

  page = null;

  adminOnlyPages = ["Settings"];
  superAdminOnlyPages = ["Settings", "Users"];

  titles = {
    'settings': 'Settings',
    'users': 'Users'
  };

  inited = false;

  bannerMessage = '';

  canShowNavigation = false;

  constructor(private router: Router,
              public userService: UserService,
              private title: Title,
              public modalService: NgbModal,
              private http: HttpClient) {}

  async ngOnInit() {
    this.title.setTitle(Utils.appName());

    this.appNavItems = this.navItems;

    try {
      if (this.userService.loggedIn) {
        this.userService.refreshProfile(() => {
          if (!this.userService.isSuperAdmin) {
            this.appNavItems = this.appNavItems.filter(item => this.superAdminOnlyPages.indexOf(item.name) === -1);
          }
          if (!this.userService.isAdmin) {
            this.appNavItems = this.appNavItems.filter(item => this.adminOnlyPages.indexOf(item.name) === -1);
          }

          this.canShowNavigation = true;
        });
      }
      else {
        this.canShowNavigation = true;
      }
    }
    catch(e) {
      console.log(e);
    }

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      this.page = null;
      let url = this.router.url;
      if (url && url[0] === "/") url = url.replace("/", "");

      Object.keys(this.titles).forEach(title => {
        if (url.startsWith(title)) {
          this.title.setTitle(Utils.appName() + ": " + this.titles[title]);
          this.page = this.titles[title];
        }
      });

      window.scrollTo(0, 0);

      this.inited = true;
    });

    let minimized = window.localStorage.getItem("sidebarMinimized");
    if (minimized === "true") this.sidebarMinimized = true;
  }

  toggleMinimized(e) {
    if (this.appSidebar) this.appSidebar.toggleMinimized(e);
  }

  toggleMinimize(e) {
    window.localStorage.setItem("sidebarMinimized", (e ? "true" : "false"));
    this.sidebarMinimized = e;
  }

  async changePassword() {
    const modalRef = this.modalService.open(ChangePasswordComponent, {
      backdropClass: 'light-grey-modal',
      windowClass: 'list-modal',
      centered: true
    });

    try { await modalRef.result; } catch(e) {}

    this.userService.refreshProfile(null);
  }
}
