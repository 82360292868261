import * as localConfig from "../../../config/local-fe.js";

export default class Utils {
  static appName() {
    return localConfig.appName;
  }

  static companyName() {
    return localConfig.companyName;
  }
}
